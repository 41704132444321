import React, { useEffect } from 'react';
import { useSpring, animated as a, config } from 'react-spring';
import useScrollAnimation from '../utils/use-scrollanimation2';
import interpolateAndClamp from '../utils/interpolate-and-clamp';

const BigHex = (props) => {
  const [anim, set] = useSpring(() => ({
    scale: -0.3,
    strokeWidth: 0,
    config: (key) => {
      if (key == 'scale') return { mass: 1, tension: 420, friction: 23 };
      else return config.default;
    },
  }));

  const [dispatch] = useScrollAnimation([props.vh]);

  useEffect(() => {
    const vh = props.vh;
    const scrollUpdate = (item) => {
      if (typeof item.scrollPos !== 'undefined' && item.scrollPos >= Math.floor(vh * 8)) {
        const pos = interpolateAndClamp([Math.floor(vh * 9.85), vh * 10.9], [0, 100], item.scrollPos);

        const hexesCompleted = pos == 0 ? 0 : Math.floor((pos / 100) * 8);
        // console.log({ hexesCompleted, pos, scrollPos: item.scrollPos, vh: vh });
        let scalePos = pos <= 12 ? 0 : Math.floor((pos / 100) * 16);
        let scale = scalePos % 2 == 0 ? scalePos * 0.05 + 0.2 : scalePos * 0.05;

        //        pos < 100 ? hexesCompleted * 0.05 + 0.3 : hexesCompleted * 0.05;

        //Math.sin(x) for x from 0 -> PI*2 will give values 0-> 1 -> 0 -> -1 -> 0
        //we want to scale from hexexComplete 1 -> 2 -> 3 as 1x -> 2.5x-> 2x -> 3.5x -> 3x
        // Math.sin(x)
        set.start(() => {
          return {
            strokeWidth: hexesCompleted * 0.2,
            scale: scale,
          };
        });
      }
    };
    scrollUpdate.id = Date.now();

    //set an initial value
    // console.log('in Effect, vh=' + vh);
    dispatch({
      type: 'registerItem',
      start: vh * 6.6,
      end: vh * 13.9,
      id: 'scrollTest',
      onUpdate: scrollUpdate,
    });
  }, [props.vh]);

  return (
    <a.div
      id="master-hex"
      style={{
        transform: anim.scale.to((scale) => `translate(552px, 74px) scale(${scale + 1.3}) rotate(90deg)`),
        width: `83px`,
      }}
    >
      <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 15" style={{ width: '100%' }}>
        <a.path
          stroke="#fff"
          fill="transparent"
          strokeWidth={anim.strokeWidth}
          d="M1,7.5 l3.6,-6.3 h7.5 l3.6,6.3 l-3.6,6.3 h-7.5 z"
        />
      </svg>
    </a.div>
  );
};

export default BigHex;
