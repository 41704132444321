import React from 'react';
import styled from 'styled-components';

const StyledMouse = styled.div`
  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(0.3vw);
    }
    100% {
      transform: translateY(0);
    }
  }

  display: none;
  position: fixed;
  right: 0;
  bottom: 2vw;
  left: 0;
  text-align: center;
  transition: opacity 0.3s ease-out;
  z-index: 5;

  .mouse {
    display: inline-block;
    position: relative;
    width: 2vw;
    height: 3vw;
    border-radius: 12px;
    border: 2px solid #fff;
    text-align: center;
  }

  &.hideThis {
    opacity: 0;
  }

  @media only screen and (min-width: 1110px) {
    & {
      display: block;
    }
  }

  & .mouse:after {
    content: '';
    position: absolute;
    top: 0.5vw;
    left: 50%;
    margin-left: -0.15vw;
    display: inline-block;
    width: 0.3vw;
    height: 0.3vw;
    background-color: #fff;
    border-radius: 50%;
    animation: scroll 1.3s ease-out infinite forwards;
  }
`;

const MouseScrollIndicator = (props) => (
  <StyledMouse className={props.isHidden ? 'hideThis' : null}>
    <div className="mouse" />
  </StyledMouse>
);

export default MouseScrollIndicator;
