import React, { useState, useEffect, useCallback } from 'react';
import { useSprings, animated as a, to } from 'react-spring';
import interpolateAndClamp from '../utils/interpolate-and-clamp';
import useScrollAnimation from '../utils/use-scrollanimation2';

const initialStyle = (position) => {
  const charsTotal = 6;
  const initialStyle = {};
  let factor =
    position < Math.ceil(charsTotal / 2)
      ? position
      : Math.ceil(charsTotal / 2) - Math.abs(Math.floor(charsTotal / 2) - position) - 1;
  initialStyle.scale = interpolateAndClamp([0, Math.ceil(charsTotal / 2)], [0.5, 2.1], factor);
  initialStyle.y = interpolateAndClamp([0, Math.ceil(charsTotal / 2)], [12, 60], factor);
  const multiplier = position < charsTotal / 2 ? 1 : -1;
  initialStyle.rotation = multiplier * interpolateAndClamp([0, Math.ceil(charsTotal / 2)], [-19, 0], factor);

  /*
  // let factor =
  //   position < Math.ceil(charsTotal / 2)
  //     ? position
  //     : Math.ceil(charsTotal / 2) - Math.abs(Math.floor(charsTotal / 2) - position) - 1;
  initialStyle.scale = interpolateAndClamp([0, charsTotal], [0.5, 2.1], position);
  initialStyle.y = 60; //interpolateAndClamp([0, Math.ceil(charsTotal / 2)], [0, 60], factor);
  // const multiplier = position < charsTotal / 2 ? 1 : -1;
  initialStyle.rotation = interpolateAndClamp([0, charsTotal], [1, 5], position);
*/

  initialStyle.blur = 12;
  initialStyle.opacity = 0;
  initialStyle.delay = position * 115;
  // initialStyle.config = { mass: 1, tension: 50, friction: 24, precision: 0.2, clamp: true };
  initialStyle.config = { mass: 16, tension: 450, friction: 150, precision: 0.2, clamp: true };
  return initialStyle;
};

const targetStyle = (i) => {
  return {
    scale: 1,
    y: 0,
    rotation: 0,
    blur: 0,
    opacity: 1,
    delay: i * 115,
  };
};

const AnimDesire = ({ vh, startAt }) => {
  const chars = ['D', 'E', 'S', 'I', 'R', 'E'];
  const [springs, springAPI] = useSprings(chars.length, initialStyle);

  const [dispatch] = useScrollAnimation([vh]);

  let scrollPosStart = Math.floor(vh * 1.12);
  let scrollPosEnd = vh * 1.75;
  if (startAt) {
    scrollPosStart = startAt - 100;
    scrollPosEnd = startAt + vh * 0.3;
  }

  const scrollUpdate = useCallback(
    (item) => {
      if (typeof item.scrollPos !== 'undefined' && item.scrollPos >= scrollPosStart - 400) {
        const progress = interpolateAndClamp([scrollPosStart, scrollPosEnd], [0, 1], item.scrollPos);
        // console.log('progress=' + progress);

        springAPI.start((index, controller) => {
          //Spring 1 progress:
          // console.log({ index, controller });

          const opacity = interpolateAndClamp(
            [0 + index / 6, 1],
            [initialStyle(index).opacity, targetStyle(index).opacity],
            progress
          );
          const scale = interpolateAndClamp(
            [0 + index / 6, 1],
            [initialStyle(index).scale, targetStyle(index).scale],
            progress
          );
          const y = interpolateAndClamp([0 + index / 6, 1], [initialStyle(index).y, targetStyle(index).y], progress);
          const blur = interpolateAndClamp(
            [0 + index / 6, 1],
            [initialStyle(index).blur, targetStyle(index).blur],
            progress
          );
          const rotation = interpolateAndClamp(
            [0 + index / 6, 1],
            [initialStyle(index).rotation, targetStyle(index).rotation],
            progress
          );
          return {
            opacity: opacity,
            scale: scale,
            y: y,
            blur: blur,
            rotation: rotation,
          };
        });
      }
    },
    [scrollPosStart, scrollPosEnd]
  );

  useEffect(() => {
    dispatch({
      type: 'registerItem',
      start: scrollPosStart - 400,
      end: scrollPosEnd + 400,
      id: 'anim-desire',
      onUpdate: scrollUpdate,
    });
    return () => {
      dispatch({ type: 'removeItem', id: 'anim-desire' });
    };
  }, [scrollPosStart, scrollPosEnd]);

  return (
    <div style={{ display: 'inline-block' }}>
      {springs.map((props, index) => (
        <a.span
          key={index}
          style={{
            display: 'inline-block',
            transform: to([props.y, props.rotation, props.scale], (y, rot, scale) => {
              const ty = y < 1 ? 0 : y;
              return `translateY(${ty}px) rotate(${rot}deg) scale(${scale})`;
            }),
            filter: to([props.blur, props.opacity], (blur, opacity) => `blur(${blur}px) opacity(${opacity})`),
          }}
        >
          {chars[index]}
        </a.span>
      ))}
    </div>
  );
};

export default AnimDesire;
