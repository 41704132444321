export const NEXTJS_BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '';

export function getStrapiURL() {
  return process.env.NEXT_PUBLIC_STRAPI_URL ?? 'http://localhost:1337';
}

export function getStrapiMedia(url) {
  if (url == null) return null;
  // console.log({ url });
  if (url.startsWith('data:')) return url;
  if (url.startsWith('http') || url.startsWith('//')) return url;
  return `${getStrapiURL()}${url}`;
}
