import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useSpring, animated as a, config } from 'react-spring';
import useScrollAnimation from '../utils/use-scrollanimation2';
import interpolateAndClamp from '../utils/interpolate-and-clamp';

const StyledHomeMobileSlider = styled.div`
  /* height: 110vh; */
  /* background: transparent; */
  /* position: sticky; */
  top: 0;
  /* overflow: hidden; */
  position: relative;
  /* margin-bottom: 80px; */
  /* height: 70vh; */
  overflow-x: hidden;
  .frame-cover {
    background-color: #101010;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  h3 {
    color: #fff;
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 100% */
    letter-spacing: 0.8px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  p {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.32px;
    &:nth-of-type(1) {
      font-size: 20px;
      font-weight: 100;
      letter-spacing: 0.4px;
    }
  }
  a {
    display: block;
    color: #41b6e6;
    letter-spacing: 0.36px;
    line-height: 144%;
    font-weight: 700;
    text-decoration: none;
    font-size: 18px;
    margin-top: 10px;
    svg {
      vertical-align: middle;
      transition: transform 400ms ease;
    }
    &:hover svg {
      transform: translateX(6px);
    }
  }
  .gatsby-image-wrapper {
    /* max-width: 370px; */
    max-width: 85%;
    height: 350px;
    right: 0;
    position: relative;
    display: block;
    margin-right: 0;
    margin-left: auto;
  }
  .copyBlock {
    margin-top: 300px;
    background-color: #172d56;
  }
`;

const HomeMobileSlider = ({ image, alt, enterVh, stackIndex, vh, startAt, last, children }) => {
  const [imgAnim, api] = useSpring(() => ({
    opacity: 0,
    pos: 0,
    x: 200,
  }));
  const [copyAnim, zzapi] = useSpring(() => ({
    x: -200,
  }));
  const [dispatch] = useScrollAnimation([vh]);

  let scrollPosStart = Math.floor(vh * (enterVh + 0.5));
  let scrollPosEnd = vh * (enterVh + 0.8);
  if (startAt) {
    scrollPosStart = startAt;
    scrollPosEnd = startAt + vh * 0.3;
  }

  const scrollUpdate = useCallback(
    (item) => {
      //Top Margin is 100px if stackIndex > 10
      let enterVhAdditional = stackIndex > 10 ? 100 : 0;

      if (typeof item.scrollPos !== 'undefined' && item.scrollPos >= scrollPosStart - 400) {
        const imgSlidePos = interpolateAndClamp(
          [scrollPosStart + enterVhAdditional, scrollPosEnd + enterVhAdditional],
          [200, 0],
          item.scrollPos
        );

        //Add 300 for the image above
        enterVhAdditional = enterVhAdditional + 300;
        const copySlidePos = interpolateAndClamp(
          [scrollPosStart + enterVhAdditional, scrollPosEnd + enterVhAdditional],
          [-200, 0],
          item.scrollPos
        );

        // console.log({ vh, enterVh, slidePos });
        api.start({ x: imgSlidePos }); //cubic easing on the y value
        zzapi.start({ x: copySlidePos });
        // zzapi.start({ opacity: opacity2, zoomFactor: zoomFactor });
      }
    },
    [scrollPosStart, scrollPosEnd, stackIndex]
  );

  useEffect(() => {
    dispatch({
      type: 'registerItem',
      start: scrollPosStart - 400,
      end: scrollPosEnd + 800,
      id: 'mobile-home-slider' + stackIndex,
      onUpdate: scrollUpdate,
    });
    return () => {
      dispatch({ type: 'removeItem', id: 'mobile-home-slider' + stackIndex });
    };
  }, [scrollPosStart, scrollPosEnd, stackIndex]);

  return (
    <>
      <StyledHomeMobileSlider style={stackIndex > 10 ? { marginTop: '100px' } : {}}>
        <a.div
          style={{
            height: '100%',
            position: 'relative',
            zIndex: -1,
            x: imgAnim.x.to((value) => `${value}px`),
          }}
        >
          <GatsbyImage image={image} alt={alt} />
        </a.div>
        <a.div
          className="copyBlock"
          style={{
            padding: '20px',
            // marginTop: '10vh',
            // marginBottom: '30vh',
            // position: 'absolute',
            marginTop: '-20px',
            width: 330,
            // zIndex: stackIndex,
            // bottom: '100px',
            // background: 'linear-gradient(0deg, #000 30%, #002f45 100%)',
            // background: 'radial-gradient(at center top, rgb(0, 46, 79), #000)',
            // background: 'black',
            // background: 'radial-gradient(farthest-corner at 100% 100%, rgb(0, 46, 79), #000)',
            // opacity: anim.opacity,
            x: copyAnim.x.to((value) => `${value}px`),
          }}
        >
          {children}
        </a.div>
      </StyledHomeMobileSlider>
    </>
  );
};

export default HomeMobileSlider;
