import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useSpring, animated as a, config } from 'react-spring';
import useScrollAnimation from '../utils/use-scrollanimation2';
import interpolateAndClamp from '../utils/interpolate-and-clamp';

const StyledStickySlider = styled.div`
  height: 110vh;
  background: transparent;
  position: sticky;
  /* z-index: ${(props) => props.stackIndex}; */
  top: 110px;
  margin-bottom: ${(props) => (props.last ? 0 : '30vh')};
  overflow: hidden;
  .frame-cover {
    background-color: #101010;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  h3 {
    color: #fff;
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 100% */
    letter-spacing: 0.8px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  p {
    color: #fff;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.32px;
    &:nth-of-type(1) {
      font-size: 20px;
      font-weight: 100;
      letter-spacing: 0.4px;
    }
    a {
      display: block;
      color: #41b6e6;
      letter-spacing: 0.36px;
      line-height: 144%;
      font-weight: 700;
      text-decoration: none;
      font-size: 18px;
      margin-top: 10px;
      svg {
        vertical-align: middle;
        transition: transform 400ms ease;
      }
      &:hover svg {
        transform: translateX(6px);
      }
    }
  }
`;

const StickySlider = ({ image, alt, enterVh, stackIndex, vh, last, children }) => {
  // const [anim, api] = useSpring(() => ({
  //   opacity: 0,
  //   pos: 0,
  // }));
  const [zz, zzapi] = useSpring(() => ({
    opacity: 0,
    zoomFactor: 1,
    viz: 1,
  }));

  const [dispatch] = useScrollAnimation([vh]);

  const scrollUpdate = useCallback(
    (item) => {
      if (typeof item.scrollPos !== 'undefined' && item.scrollPos >= Math.floor(vh * enterVh)) {
        // const opacity1 = interpolateAndClamp(
        //   [Math.floor(vh * (enterVh + 0.9)), vh * (enterVh + 1.1)],
        //   [0, 1],
        //   item.scrollPos
        // );

        // overshoot the end values by 10% so when scrolling the other way there is time to reset to the end value before it comes into view.
        const opacity2 = interpolateAndClamp(
          [Math.floor(vh * (enterVh + 1.5)), vh * (enterVh + 2.25)],
          [0, 0.8],
          item.scrollPos
        );
        const zoomFactor = interpolateAndClamp(
          [Math.floor(vh * enterVh), vh * (enterVh + 2.25)],
          [0, 11],
          item.scrollPos
        );

        if (last !== true && item.scrollPos > vh * (enterVh + 2.25)) {
          zzapi.set({ opacity: 0.44, viz: 0, opacity: 0.7, zoomFactor: 10 });
        } else {
          // api.start({ opacity: opacity1, y: 50 * (1 - Math.pow(1 - opacity1, 3)) }); //cubic easing on the y value
          zzapi.set({ viz: 1 });
          zzapi.start({ opacity: opacity2, zoomFactor: zoomFactor });
        }
      }
    },
    [vh, enterVh, stackIndex, last]
  );

  useEffect(() => {
    dispatch({
      type: 'registerItem',
      start: vh * (enterVh - 1),
      end: vh * (enterVh + 3.5),
      id: 'sticky-slider' + stackIndex,
      onUpdate: scrollUpdate,
    });
    return () => {
      dispatch({ type: 'removeItem', id: 'sticky-slider' + stackIndex });
    };
  }, [vh, stackIndex, enterVh]);

  return (
    <>
      <StyledStickySlider stackIndex={stackIndex} last={last}>
        <a.div
          style={{
            height: '100%',
            opacity: zz.viz,
            transform: zz.zoomFactor.to((value) => {
              const ty = value * 1.35;
              const sc = 1 + value / 100;
              return `translateY(-${ty}%) scale(${sc}) translateZ(0)`;
            }),
            willChange: 'transform',
          }}
        >
          <GatsbyImage
            image={image}
            alt={alt}
            style={{
              height: last ? '110%' : '100%',
            }}
          />
        </a.div>
        <a.div className="frame-cover" style={{ opacity: zz.opacity, willChange: 'opacity' }}></a.div>
        <a.div
          style={{
            padding: '30px 71px',
            // marginTop: '10vh',
            // marginBottom: '30vh',
            position: 'absolute',
            width: 230,
            zIndex: stackIndex,
            top: '30px',
            // background: 'radial-gradient(at center top, rgb(0, 46, 79), #000)',
            background: 'black',
            // opacity: anim.opacity,
            // y: anim.pos.to((value) => `${value}px`),
          }}
        >
          {children}
        </a.div>
      </StyledStickySlider>
    </>
  );
};

export default StickySlider;
